// @flow
import * as actions from './actions'
import type { Action, ReportUserStatusUpdateProgress } from './types'
import type { CachedReport, Report } from 'types'

import { updateReport, reportUserStatusUpdateProgress } from './reducer.helper'
import { prepareCacheReports } from './helpers'

export type State = {
  data: Report[],
  cachedReports: CachedReport[],
  facets: {},
  filter: {},
  errors: Object[],
  sort: string,
  page: { offset: number, limit: number },
  total: number,
  reportUserStatusUpdateProgress: ReportUserStatusUpdateProgress,
  dataFetched: boolean,
  isFetchingReports: boolean,
  reportInProgress?: string,
}

export const initalFilterState = {
  page: { offset: 0, limit: 50 },
  filter: {},
  sort: '-generation_time'
}

export const initialState = {
  data: [],
  facets: {},
  errors: [],
  total: 0,
  reportUserStatusUpdateProgress: {},
  dataFetched: false,
  isFetchingReports: false,
  preConfiguredFilterStatus: true,
  cachedReports: [],
  ...initalFilterState
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case actions.CACHE_MORE_REPORTS_SUCCESS:
      const { data: moreReports = [] } = action
      return {
        ...state,
        cachedReports: prepareCacheReports(moreReports, state.cachedReports, state.sort)
      }
    case actions.CACHE_MORE_REPORTS_FAILURE:
      return { ...state, errors: action.errors }
    case actions.API_REPORTS_INDEX:
      return { ...state, isFetchingReports: true }
    case actions.API_REPORTS_INDEX_SUCCESS:
      const { data, meta } = action
      if (data && meta) {
        return {
          ...state,
          data,
          total: meta.total,
          dataFetched: true,
          isFetchingReports: false,
          cachedReports: prepareCacheReports(data, [], state.sort)
        }
      }
      return state
    case actions.API_REPORTS_FACETS_INDEX_SUCCESS:
      const { data: facets } = action
      if (facets) {
        return {
          ...state,
          facets
        }
      }
      return state
    case actions.FETCH_REPORT_DETAIL_SUCCESS:
      const { data: report } = action
      const index = state.data.findIndex(item => item.id === report.id)
      if (index > -1) {
        const newData = [
          ...state.data.slice(0, index),
          report,
          ...state.data.slice(index + 1)
        ]
        return {
          ...state,
          data: newData
        }
      } else {
        return state
      }
    case actions.API_REPORTS_INDEX_FAILURE:
      return { ...state, errors: action.errors }
    case actions.UPDATE_REPORT_USER_STATUS:
      const { userStatus: { reportId } = {} } = action
      return {
        ...state,
        reportUserStatusUpdateProgress: reportUserStatusUpdateProgress(reportId, true, state.reportUserStatusUpdateProgress)
      }
    case actions.UPDATE_REPORT_USER_STATUS_SUCCESS:
      const { data: { id } = {} } = action
      return {
        ...state,
        data: updateReport(action.data, state.data),
        reportUserStatusUpdateProgress: reportUserStatusUpdateProgress(id, false, state.reportUserStatusUpdateProgress)
      }
    case actions.UPDATE_REPORT_USER_STATUS_FAILURE:
      return { ...state, errors: action.errors }
    case actions.API_REPORTS_SET_ALL_FILTERS:
      const { filter, sort, page } = action
      return { ...state, filter, sort, page, isFetchingReports: true }
    case actions.SET_PRE_CONFIGURED_FILTER_STATUS:
      return { ...state, preConfiguredFilterStatus: action.value }
    case actions.SET_REPORT_IN_PROGRESS:
      return {
        ...state,
        reportInProgress: action.payload.reportId
      }
    case actions.SET_SINGLE_REPORT_REVIEW_STATUS:
      return {
        ...state,
        reportInProgress: null,
        data: state.data.map<Report>(
          (report) => report.id === action.payload.reportId ? {...report, reviewStatus: action.payload.reviewStatus} : report
        )
      }
    default:
      return state
  }
}

export default reducer
