
//
// Work out the API URL dynamically. Depending on the build config, the API URL can be set in
// several ways:
//
// 1. Environment variable: REACT_APP_API_URL
// 2. Local storage: api_url
// 3. Hostname mapping: REACT_APP_API_HOSTNAMES { "hostname": "api_url" }
// 4. API options: REACT_APP_API_OPTIONS (used to set local storage) { "api_url": "label" }
//
const env = process.env
// HOSTNAME_MAP is structured { "hostname": "api_url" }
const HOSTNAME_MAP = env.REACT_APP_API_HOSTNAMES ? JSON.parse(env.REACT_APP_API_HOSTNAMES) : {}
// API_OPTIONS is structured { "api_url": "label" }
const API_OPTIONS = env.REACT_APP_API_OPTIONS ? JSON.parse(env.REACT_APP_API_OPTIONS) : {}

// Detect the API URL based on...
const API_URL =
  env.REACT_APP_API_URL || // 1. Environment variable
  localStorage.getItem('api_url') || // 2. Local storage settings
  HOSTNAME_MAP[window.location.hostname] || // 3. The current hostname
  Object.keys(API_OPTIONS)[0] // 4. First API in the list

if (!API_URL) throw new Error('Could not infer API hostname')

// If we're on a known hostname, don't allow the user to select an API. e.g. we always want
// myqap-staging.rcpaqap.com.au to use the staging API.
const SELECTABLE_ENDPOINTS = HOSTNAME_MAP[window.location.hostname]
  ? { [API_URL]: API_OPTIONS[API_URL] }
  : API_OPTIONS

export {
  API_URL,
  SELECTABLE_ENDPOINTS
}
