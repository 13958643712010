// @flow
import {
  API_URL,
  JWT_AUTH_ENDPOINT,
  PASSWORD_AUTH_ENDPOINT,
  USER_SESSION_ENDPOINT,
} from 'qap/constants'
import { requestActions } from 'state/request'
import { failure, success } from 'utilities/actions'
import { decamelizeKeys } from 'utilities/humps'

export const API_SESSION_PASSWORD_AUTH_CREATE = 'API_SESSION_PASSWORD_AUTH_CREATE'
export const API_SESSION_JWT_AUTH_CREATE = 'API_SESSION_JWT_AUTH_CREATE'
export const CLEAN_STORE_STATE = 'CLEAN_STORE_STATE'
export const LOGOUT_USER = 'LOGOUT_USER'
export const AUTH_EXPIRE_SESSION = 'AUTH_EXPIRE_SESSION'
export const API_SESSION_FETCH = 'API_SESSION_FETCH'
export const INITIALIZE_LOGIN = 'INITIALIZE_LOGIN'
export const API_SESSION_CLIENT_SETTINGS_UPDATE = 'API_SESSION_CLIENT_SETTINGS_UPDATE'

type passwordAuthData = { email: string, password: string, otpCode?: string }
type ClientSetting = {key: string, value: Object}

export const passwordAuth = (data: passwordAuthData, redirect: ?Object | ?string) => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}/${PASSWORD_AUTH_ENDPOINT}`,
    successAction: success(API_SESSION_PASSWORD_AUTH_CREATE),
    failureAction: failure(API_SESSION_PASSWORD_AUTH_CREATE),
    body: { data: decamelizeKeys({ ...data}) },
    redirect
  }
}

export const jwtAuth = (data: { token: string }, redirect?: string, notification?: string) => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}/${JWT_AUTH_ENDPOINT}`,
    successAction: success(API_SESSION_JWT_AUTH_CREATE),
    failureAction: failure(API_SESSION_JWT_AUTH_CREATE),
    body: { data },
    redirect,
    notification
  }
}

export const fetchSessionInfo = () => ({
  type: requestActions.AUTHED_REQUEST,
  method: 'GET',
  url: `${API_URL}/${USER_SESSION_ENDPOINT}`,
  successAction: success(API_SESSION_FETCH),
  failureAction: failure(API_SESSION_FETCH)
})

export const logoutUser = () => {
  localStorage.removeItem('organisation_ids')
  return { type: LOGOUT_USER }
}

export const cleanStoreState = () => ({
  type: CLEAN_STORE_STATE
})

export const expireSession = (location?: Object) => ({
  type: AUTH_EXPIRE_SESSION,
  location
})

export const initializeLogin = () => ({
  type: INITIALIZE_LOGIN
})

export const updateClientSettings = ({ key, value }: ClientSetting) => ({
  type: API_SESSION_CLIENT_SETTINGS_UPDATE,
  payload: {
    key,
    value,
  },
})
