// @flow
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchSystemStatus } from '../../state/api/actions'

const useAPIStatus = () => {
  const dispatch = useDispatch()
  const apiStatus = useSelector((state) => state.api?.systemStatus) || {}

  useEffect(() => {
    dispatch(fetchSystemStatus())
  }, [])

  return apiStatus
}

export default useAPIStatus
