import React from 'react'
import { Typography, Select, MenuItem } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

import { API_URL, SELECTABLE_ENDPOINTS } from 'qap/constants'

const styles = theme => ({
  environment: {
    color: 'white',
    textTransform: 'uppercase'
  }
})

const ApiSelector = ({ classes }) => {
  const options = Object.entries(SELECTABLE_ENDPOINTS)

  // Find the label for the current API_URL
  const renderValue = value => {
    const label = SELECTABLE_ENDPOINTS[value] || value
    return <Typography variant='subtitle1' className={classes.environment}>{label}</Typography>
  }

  // Update the API_URL in localStorage and reload the page
  const handleChange = event => {
    const { value } = event.target
    localStorage.setItem('api_url', value)
    window.location.reload()
  }

  return options.length < 2
    ? renderValue(API_URL)
    : <Select
      value={API_URL}
      variant='standard'
      renderValue={renderValue}
      onChange={handleChange}
    >
      {options.map(([url, label]) => (
        <MenuItem key={url} value={url}>{label}</MenuItem>
      ))}
    </Select>
}

export default withStyles(styles)(ApiSelector)
