// @flow
// $FlowFixMe
import * as Sentry from '@sentry/browser'
import { APP_RELEASE_VERSION, SENTRY_DSN } from 'qap/constants'
import { getUserIdFromLocalStorage } from 'utilities/user'

// Send the error stack to Sentry
// https://blog.sentry.io/2017/09/28/react-16-error-boundaries
export const noticeError = (
  error: any = new Error('Sentry - myQAP Front-end Portal'),
  extra: any = {},
) => {
  if (!SENTRY_DSN) return

  if (typeof error === 'string') Sentry.captureMessage(error)
  else if (error instanceof Error) Sentry.captureException(error, extra)
  else Sentry.captureException(new Error(error))
}

export const setAPIReleaseVersion = (version: string) => {
  if (!SENTRY_DSN) return

  Sentry.getGlobalScope().addEventProcessor((event) => {
    event.tags = { ...event.tags, api_version: version }
    return event
  })
}

export const addUserIdToEvent = (event: Sentry.Event) => {
  event.extra = { ...event.extra, user_id: getUserIdFromLocalStorage() }
  return event
}

const setUserId = (userId: ?string) => Sentry.setUser(userId ? { id: userId } : null)

export default {
  init: () =>
    Sentry.init({
      dsn: SENTRY_DSN,
      release: APP_RELEASE_VERSION,
      // Synchronously add user_id from localStorage as fallback
      // for events that occur before async setUserId() completes
      beforeSend: addUserIdToEvent,
    }),
  noticeError,
  setAPIReleaseVersion,
  setUserId,
}
