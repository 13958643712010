// @flow
import { getDifferenceByProperty } from 'utilities/getDifferenceByProperty'

import type { CachedReport, Report } from 'types'

export const getSortedReports = (reports: CachedReport[], sort?: string): CachedReport[] => (
  reports
    .sort((a, b) => {
      if (!sort || sort === "-generation_time") {
        return new Date(b.generationTime) - new Date(a.generationTime)
      } else if (sort === "generation_time") {
        return new Date(a.generationTime) - new Date(b.generationTime)
      }
      return 0
    })
)

export const getCacheableReportData = (data: Report[]): CachedReport[] => (
  data.map(report => ({
    id: report.id,
    generationTime: report.generationTime,
  }))
)

export const getCombinedReports = (newReports: CachedReport[], existingReports: CachedReport[]): CachedReport[] => {
  const uniqueNewReports = getDifferenceByProperty(newReports, existingReports)
  return [...existingReports, ...uniqueNewReports]
}

export const prepareCacheReports = (newReports: Report[], existingReports: CachedReport[] = [], sort?: string) => {
  const cacheableNewReports = getCacheableReportData(newReports)
  const combinedCacheableReports = getCombinedReports(cacheableNewReports , existingReports)
  return getSortedReports(combinedCacheableReports, sort)
}


export const getMinReportsToFetch = (reportsCount: number, offset: number, limit: number): number => {
   // pull all the reports from the first record up to the current-page (i.e. cumulativeReportsSize)
   const cumulativeReportsSize = offset + limit
   /***
    minReportsToFetch could be either of the following:
    * on first load (triggered on visiting first/last report from the reports-list):
    * if user starts at the first page, --> `offset+limit` which is also equal to `cachedReports.length`
    * if user starts at any page other than the first, --> `offset+limit`
    * when more reports are fetched (triggered on visiting next/prev link in report-page):
    * `cachedReports.length` because `cachedReports` keeps appending more reports
    after fetching `offset+limit` records the first time
    ***/
  return Math.max(cumulativeReportsSize, reportsCount)
}