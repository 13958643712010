// @flow
const env = process.env
export const PRE_CONFIGURED_FILTER_PATH_KEYS = env.REACT_APP_PRE_CONFIGURED_FILTER_PATH_KEYS || 'certificates,reports'
export const PRE_CONFIGURED_FILTER_ENABLED = env.REACT_APP_PRE_CONFIGURED_FILTER_ENABLED || 'false'
export const ENROLMENT_YEAR_FILTER_COUNT = +env.REACT_APP_ENROLMENT_YEAR_COUNT || 2
export { API_URL, SELECTABLE_ENDPOINTS } from './constants/api'
export const JIRA_COLLECTOR_URL = env.REACT_APP_JIRA_COLLECTOR_URL
export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN
export const APP_RELEASE_VERSION = env.REACT_APP_RELEASE_TAG || ''
export const SYSTEM_STATUS = 'system/status'
export const API_ROOT = 'api'
export const FEATURE_TOURS_ENDPOINT = 'api/session/feature_tours'
export const ORGANISATIONS_ENDPOINT = 'api/organisations'
export const PASSWORD_RESET_REQUEST_ENDPOINT = 'api/password_reset_requests'
export const PROGRAM_EVENTS_ENDPOINT = 'api/program_events'
export const CERTIFICATES_ENDPOINT = 'api/certificates'
export const REPORTS_ENDPOINT = 'api/reports'
export const REPORT_DOWNLOAD_ENDPOINT = 'api/reports/download'
export const CERTIFICATE_DOWNLOAD_ENDPOINT = 'api/certificates/download'
export const ROUND_ENROLMENTS_ENDPOINT = 'api/round_enrolments'
export const RESULT_ENTRY_STATS = 'api/round_enrolments/counts'
export const REPORT_REVIEW_STATS = 'api/reports/counts'
export const SURVEY_ROUND_SUBMISSIONS_ENDPOINT = 'api/survey_round_submissions'
export const SIGNUPS_ENDPOINT = 'api/signups'
export const USER_SESSION_ENDPOINT = 'api/session'
export const PASSWORD_AUTH_ENDPOINT = 'api/session/password_auth'
export const JWT_AUTH_ENDPOINT = 'api/session/jwt_auth'
export const EMAIL_NOTIFICATION_PREFERENCE_ENDPOINT = 'api/email_preferences'
export const GLOBAL_NOTIFICATIONS_ENDPOINT = 'api/global_notifications'
export const CUSTOM_FOLLOW_UP_LABELS_ENDPOINT = 'api/custom_follow_up_labels'
export const SALESFORCE_PORTAL_JWT_ENDPOINT = 'api/salesforce_portal_jwt'
export const CLIENT_SETTINGS_ENDPOINT = '/api/session/client_settings'

export const LOGIN_URL = '/login'
export const PASSWORD_RESET_URL = '/password-reset'
export const LANDING_PAGE_URL = '/'
export const CORPORATE_SITE_URL = 'https://rcpaqap.com.au'
export const DATA_ANALYSIS_ASSESSMENT_CRITERIA_URL = 'https://dataanalysis.rcpaqap.com.au'
export const HL7_TOOLS_URL = env.REACT_APP_HL7_TOOLS_URL || ''
export const CERTIFICATES_PAGE_URL = '/certificates'
export const REPORTS_PAGE_URL = '/reports'
export const REPORTS_OVERVIEW_PAGE_URL = '/reports-overview'
export const ROUND_ENROLMENTS_PAGE_URL = '/round-enrolments'
export const ROUND_ENROLMENTS_OVERVIEW_PAGE_URL = '/round-enrolments-overview'
export const ANALYTICS_PAGE_URL = '/analytics'
export const ENROLMENTS_TAB_PAGE_URL = '/enrolments-tab'
export const INVOICES_TAB_PAGE_URL = '/invoices-tab'
export const REQUESTS_TAB_PAGE_URL = '/requests-tab'
export const DASHBOARD_PAGE_URL = '/dashboard'
export const USER_PREFERENCE_URL = '/preferences'
export const DATA_EXTRACT_PAGE_URL = '/data-extract'
export const USER_CALENDAR_PREFERENCE_URL = `${USER_PREFERENCE_URL}/calendar-subscriptions`
export const USER_EMAIL_PREFERENCE_URL = `${USER_PREFERENCE_URL}/email-notifications`
export const CALENDAR_PREFERENCE_ENDPOINT = 'api/calendar_preferences'
export const CALENDAR_CSV_ENDPOINT = 'api/calendars/export'
export const CUSTOM_FOLLOW_UP_LABELS_URL = `${USER_PREFERENCE_URL}/custom-follow-up-labels`
export const GENERAL_PREFERENCE_ENDPOINT = 'api/user_preferences'
export const MFA_ENROLMENT_ENDPOINT = 'api/mfa_enrolment'
export const MFA_UNENROLMENT_ENDPOINT = 'api/mfa_unenrolment'
export const LOGOUT_ENDPOINT = 'api/session/sign_out'

export const SF_PORTAL_BASE_URL = env.REACT_APP_SALESFORCE_PORTAL_BASE_URL || ''
export const SF_ENROLMENTS_TAB = `${SF_PORTAL_BASE_URL}/apex/EnrolmentPortal`
export const SF_INVOICES_TAB = `${SF_PORTAL_BASE_URL}/apex/invoiceList`
export const SF_REQUESTS_TAB = `${SF_PORTAL_BASE_URL}/apex/requestList`
export const SF_HOME_TAB = `${SF_PORTAL_BASE_URL}/apex/Home`
export const SF_PORTAL_AUTH_REDIRECT_URL = env.REACT_APP_SF_PORTAL_AUTH_REDIRECT_URL || SF_HOME_TAB
export const SF_PORTAL_FULL_ACCESS = env.REACT_APP_SALESFORCE_PORTAL_FULL_ACCESS

export const SUPPORT_EMAIL = 'customer.service@rcpaqap.com.au'

export const HELP_URL = env.REACT_APP_HELP_URL || 'https://myqaphelp.rcpaqap.com.au/'
export const PRIVACY_POLICY_URL = 'https://rcpaqap.com.au/privacy-policy/'
export const TERMS_OF_TRADE_URL = 'https://rcpaqap.com.au/terms-conditions/'
export const LATE_ENROLMENT_POLICY_URL = 'https://myqapdocs.s3.amazonaws.com/20200408/Late+and+Amended+Results+and+Appeals+Policy_b0a9d362-91f3-d32a-245d-c615546cca1a.pdf'
export const PASSWORD_STRENGTH_CHECK_CDN_URL = 'https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js'

export const MINIMUM_PASSWORD_LENGTH = 8
export const PASSWORD_LENGTH_ERROR =
  `Password must be ${MINIMUM_PASSWORD_LENGTH} characters or more`
export const PASSWORDS_NO_MATCH_ERROR = 'Passwords do not match'
export const PWNED_PASSWORD_ERROR =
  'This password has previously appeared in a data breach and should not be used. Please choose a different password.'
export const PASSWORD_SET_MESSAGE = 'This link has expired. Please log in below.'
export const PASSWORD_EXPIRED_MESSAGE = 'This link has expired. Use reset password below to get a new link.'
export const SUBMISSION_CONFIRMATION_MESSAGE = 'Ensure that all results and methods are entered correctly'
export const SINGLE_SUBMISSION_MESSAGE_SUBSTR = ', as you can only submit results once.'
export const ENV_BANNER_ENABLED = env.REACT_APP_ENV_BANNER_ENABLED === 'true'

export const ENV_GOOGLE_ANALYTICS_MEASUREMENT_ID = env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''

export const UPDATE_RESULTS_DELAY = 10000

export const MESSAGE_DISPLAY_TIMEOUT = 2000

export const REPORT_CHART_FRESHNESS_DAYS = 14

export const REPORTS_IN_PROGRESS_TAB_FRESHNESS_DAYS = env.REACT_APP_REPORTS_IN_PROGRESS_TAB_FRESHNESS_DAYS || 30
export const REPORTS_NOT_REVIEWED_TAB_FRESHNESS_DAYS = env.REACT_APP_REPORTS_NOT_REVIEWED_TAB_FRESHNESS_DAYS || 14
export const DASHBOARD_REPORT_TYPE = env.REACT_APP_DASHBOARD_REPORT_TYPE || 'Survey,Supervisor,Yearly,Final'
export const REPORTS_OVERVIEW_TABLE_HINT_NOT_REVIEWED = env.REACT_APP_REPORTS_OVERVIEW_TABLE_HINT_NOT_REVIEWED
  || `Reports that have not been reviewed after ${REPORTS_NOT_REVIEWED_TAB_FRESHNESS_DAYS} days of release`

// TODO:
// * Ensure that 'not_started' status is always labelled as Not Reviewed
// * if yes, refactor: Replace ROUND_ENROLMENT_STATUS_LABELS & REVIEW_STATUS with RESULT_ENTRY_STATUSES & REPORT_REVIEW_STATUSES
export const REPORT_REVIEW_STATUSES = {
  NOT_REVIEWED: {label: `Not Reviewed After ${REPORT_CHART_FRESHNESS_DAYS} Days`, value: 'not_started'},
  IN_PROGRESS: {label: 'In Progress', value: 'in_progress'},
  DONE: {label: 'Done', value: 'done'},
  SIGNED_OFF: {label: 'Signed Off', value: 'signed_off'},
}

export const RESULT_ENTRY_STATUSES = {
  MISSED: {label: 'Missed', value: 'missed'},
  LATE: {label: 'Late', value: 'submitted'},
  SUBMITTED: {label: 'On Time', value: 'submitted'},
}

export const ROUND_ENROLMENT_STATUS_LABELS: Object = {
  draft: 'Draft',
  missed: 'Missed',
  open: 'Enter Results',
  submitted: 'Submitted',
  upcoming: 'Not Open',
  prepared: 'Not Open',
  cancelled: 'Cancelled'
}

export const ROUND_ENROLMENT_INACTIVE_STATUSES = [
  'upcoming',
  'prepared'
]

export const REVIEW_STATUS = {
  notStarted: 'not_started',
  inProgress: 'in_progress',
  done: 'done',
  overdue: 'overdue'
}

export const APPLICATION_ERRORS = {
  '400': {
    description: 'Bad Request',
    title: 'Request could not be processed',
    message: 'Something went wrong. The team has been alerted.',
    action: {
      label: 'OK'
    }
  },
  '403': {
    description: 'Forbidden',
    title: 'Not permitted',
    message: 'You do not have permission to perform this action. Try logging out and in again.',
    action: {
      label: 'OK'
    }
  },
  '404': {
    description: 'Not Found',
    title: 'Requested resource not found',
    message: 'We couldn’t find what you’re looking for.',
    action: {
      label: 'Back Home',
      path: '/'
    }
  },
  '429': {
    description: 'Too Many Requests',
    title: 'Rate limit reached',
    message: 'Too many requests. Please try again after {retryAfter} seconds.',
    action: {
      label: 'OK'
    }
  },
  '500': {
    description: 'Internal Server Error',
    title: 'Oops, something went wrong',
    message: 'Something went wrong. The team has been alerted.',
    action: {
      label: 'OK'
    }
  },
  '502': {
    description: 'Bad Gateway',
    title: 'Service Unavailable',
    message: 'The service is temporarily unavailable. Please try again later.',
    action: {
      label: 'OK'
    }
  },
  '503': {
    description: 'Service Unavailable',
    title: 'Service Unavailable',
    message: 'The service is temporarily unavailable. Please try again later.',
    action: {
      label: 'OK'
    }
  },
  'failed_to_fetch': {
    description: 'Service Unavailable',
    title: 'Service Unavailable',
    message: 'The service is temporarily unavailable. Please try again later.',
    action: {
      label: 'OK'
    }
  }
}

export const INVALID_OTP_CODE = 'invalid_otp_code'
// TODO: CLEANUP: After deploying RD-6365 to portal-api, only keep `invalid_email_or_password` as login-error-code
export const LOGIN_ERROR_CODES = ['invalid_email', 'invalid_password', 'invalid_email_or_password']
export const VERIFICATION_ERROR_CODES = ['otp_required', INVALID_OTP_CODE]

export const MEASURAND_TYPE = {
  numeric: 'NUMERIC',
  text: 'TEXT',
  list: 'LIST',
  date: 'DATE',
  file: 'FILEUPLOAD'
}

export const DOC_TYPES = Object.freeze({
  report: 'REPORT',
  certificate: 'CERTIFICATE'
})

export const FOLLOW_UP_LABEL_ICON_LIST = [
  'Alarm',
  'ArrowUpward',
  'ArrowDownward',
  'Bookmark',
  'Check',
  'ChatBubble',
  'Close',
  'Flag',
  'NotInterested',
  'PriorityHigh',
  'Redo',
  'RemoveCircle',
  'Report',
  'ReportProblem',
  'Schedule',
  'Search',
  'Stars',
  'ThumbDown',
  'ThumbUp'
]

export const DEFAULT_USER_TIMEZONE = 'Sydney'

export const FILE_UPLOAD_STATUS = {
  pending: 'pending',
  uploading: 'uploading',
  uploaded: 'uploaded'
}

export const DEFAULT_UPLOAD_CHUNK_SIZE = (+env.REACT_APP_UPLOAD_CHUNK_SIZE) || (8 * 1024 * 1024) // default size: 8MB

export const RESULT_ENTRY_SAMPLE_NUMBER_MAX = +env.REACT_APP_RESULT_ENTRY_SAMPLE_NUMBER_MAX || 50

export const DATA_ANALYTICS_LR_MAX_NUM_OF_STATIC_COLS = +env.REACT_APP_DATA_ANALYTICS_LR_MAX_NUM_OF_STATIC_COLS || 8

export const DISPATCH_SCHEDULE_URL = env.REACT_APP_DISPATCH_SCHEDULE_URL || ''

// Feature: merge configurations for CLASSIC_PORTAL_ACCESS, HL7_ACCESS, EXPERIMENTAL_FEATURE_ACCESS
export const CLASSIC_PORTAL_ACCESS = {
  viewEnrolments: 'view_classic_portal_enrolments',
  viewInvoices: 'view_classic_portal_invoices',
  viewRequests: 'view_classic_portal_requests',
  viewTrainings: 'view_classic_portal_trainings',
  viewHome: 'view_classic_portal_home'
}

export const HL7_ACCESS = {
  viewHL7Tools: 'view_hl7_tools',
}

export const EXPERIMENTAL_FEATURE_ACCESS = {
  viewDashboard: 'view_dashboard',
}

// New Relic Configurations
export const NEW_RELIC_OPTION_INIT = env.REACT_APP_NEW_RELIC_OPTION_INIT || '{}' // NREUM.init
export const NEW_RELIC_OPTION_INFO = env.REACT_APP_NEW_RELIC_OPTION_INFO || '{}' // NREUM.info
export const NEW_RELIC_OPTION_LOADER_CONFIG = env.REACT_APP_NEW_RELIC_OPTION_LOADER_CONFIG || '{}' // NREUM.loader_config

export const FEATURE_TOUR_SETTINGS =
  env.REACT_APP_FEATURE_TOUR_SETTINGS ?
    JSON.parse(env.REACT_APP_FEATURE_TOUR_SETTINGS)
  : Object.freeze({
      reportPage: true,
      submissions: true,
      reportsPage: true,
      reportMeasurand: true,
      roundEnrolmentResultsTab: true,
      userPreference: true,
      dataAnalytics: true,
    })
